body {
  margin: 0;
  height: 100vh;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td > span.sv-string-viewer::after {
  content: "*";
  color: #e60a3e;
}

.sd-btn--action {
  background-color: #1266f1;
}

/* 
  .sd-item--checked .sd-item__decorator {
    background:#1266F1 ;
} */

#surveyElement {
  --primary: #1266f1;
  --secondary: #1266f1;
}

.align-items-center {
  align-items: center;
}

.color-grey,
.auditViewC span.color-grey,
.escalationViewC span.color-grey {
  color: #9c9fa0;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    #ee7724,
    #d8363a,
    #dd3675,
    #b44593
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

.gradient-custom-3 {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.site-layout {
  margin-top: -24px;
}

.scrollbar {
  width: 100vw;
  overflow-y: auto;
  height: 70vh;
  margin-bottom: 25px;
}
.single-page .scrollbar .text11 {
  display: block;
  margin: 24px 0 0;
}
.auditFormsDetailsRS .scrollbar .text11 {
  font-size: 16px;
}
.table-count-area .count-inner {
  padding: 7px;
}
/* 
.ant-layout-sider-children{
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
} */

.background-radial-gradient {
  height: 100vh;
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    );
}

/* .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
} */

.all-table-holder,
.table-count-area {
  padding: 10px 20px;
}

.all-content .table-count-area {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #9c9fa0;
  width: 100%;
  height: auto;
  overflow: hidden;
  clear: both;
}

.all-table-holder {
  border-top: 0.5px solid #e0e0e0;
  overflow-x: auto;
  background-color: #ffffff;
}
.all-content {
  position: relative;
}

.filterboxs {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 7px 14px;
  margin: 0 0 0 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #152327;
}

.saveUserProfileImgs {
  padding: 6px 10px;
  color: #ffffff;
  background: #000000;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5.5px;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.5s;
  /* float: right; */
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  display: block;
  max-width: fit-content;
  cursor: pointer;
}

.addNewbtn,
.addNewbtnVform {
  padding: 5px 9px;
  color: #ffffff;
  background: #000000;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5.5px;
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.5s;
  /* float: right; */
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  display: block;
  max-width: fit-content;
  cursor: pointer;
}

.addNewbtn {
  margin-left: auto;
}
.single-page .table-count-area {
  border-bottom: 0.5px solid #e0e0e0;
  padding: 17px 18px;
}

.commonTCont,
.roleCont {
  margin: 10px 0px 5px 0px;
}

.commonTCont,
.roleCont,
.settingsContainer {
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(13, 25, 36, 0.06);
  border: 1px solid #e0e0e0;
  padding-bottom: 5px;
}
.settingsContainer {
  margin: 35px 35px 0px;
}

.commonTCont,
.roleCont .all-table-holder,
.settingsContainer .all-table-holder {
  border-top: 0px;
}
.addNewbtn:hover {
  color: #ffffff;
  background-color: #b22d2b;
}

#antdformstyle .ant-form-item-label > label {
  font-weight: 600;
  font-size: 16px;
}

#antdformstyle .ant-form-item-control-input,
#antdformstyle
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input,
#antdformstyle
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto !important;
}

#antdformstyle
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0.2rem 11px !important;
  border-radius: 0.25rem !important;
  -webkit-border-radius: 0.25rem !important;
  -moz-border-radius: 0.25rem !important;
  -ms-border-radius: 0.25rem !important;
  -o-border-radius: 0.25rem !important;
}

#antdformstyle .ant-input,
.ant-picker,
.ant-picker-focused {
  padding: 0.5rem !important;
  border-radius: 0.25rem !important;
  -webkit-border-radius: 0.25rem !important;
  -moz-border-radius: 0.25rem !important;
  -ms-border-radius: 0.25rem !important;
  -o-border-radius: 0.25rem !important;
}

#antdformstyle .submit-btn {
  color: #ffffff;
  background: #b22d2b;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 28px;
  line-height: normal;
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

button.submit-btn {
  color: #ffffff !important;
  background: #5e6278 !important;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 30px;
  line-height: normal;
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

div.all-modal {
  top: 30px;
}
.all-modal .ant-modal-header {
  background-color: #5e6278;
}

.headermodaltitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.all-modal .ant-modal-close {
  color: rgb(255 255 255);
}

.all-modal .form-label-antd {
  font-weight: 600;
  font-size: 14px;
  color: #474747;
  margin-bottom: 5px;
}

.all-modal .formtitle {
  font-weight: 600;
  font-size: 17px;
  color: #474747;
  margin-bottom: 5px;
}

.separator2 {
  width: 0px;
  height: 31.5px;
  border: 0.5 solid #e0e0e0;
}

.separator3 {
  width: 100%;
  height: 0px;
  border: 0.5px solid #e0e0e0;
}

/* .main-layout {
  display: flex;
  justify-content: space-between;
} */

.bottom-users,
.scoreInfo .bottom-users {
  font-size: 16px;
  font-weight: 600;
}

.bottom-users > span {
  margin: 0 0 0 10px;
}

.back-btn {
  cursor: pointer;
}
.text11 {
  font-weight: 600;
  font-size: 23px;
  line-height: 24px;
  color: #152327;
}
.text12 {
  position: absolute;
  left: 400px;
  top: 20px;
  font-weight: 600;
  font-size: 23px;
  line-height: 24px;
  color: #152327;
}
.inner-single-page {
  padding: 20px 22px 20px 25px;
}
.right-side {
  width: 100%;
  float: right;
  width: calc(100% - 280px);
}
.right-side.detailPage {
  width: 100%;
}

.tableAuditForms {
  width: 100% !important;
}

.audit-detail-buttons {
  margin: 15px 40px 15px 15px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  background: rgb(22, 173, 82);
  border-radius: 5px;
}

.auditedVInfo .audit-detail-buttons {
  color: rgb(255, 255, 255) !important;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 10px 0 0;
  padding: 10px 17px;
  height: auto;
  font-weight: 600;
  letter-spacing: 0.02px;
  border: none;
}

.auditedVInfo .audit-detail-buttons {
  background: rgb(22, 173, 82) !important;
}
.auditedVInfo .dispute-btn {
  background-color: red !important;
}

.auditedVInfo .acceptFeedbackbtn {
  background-color: #1266f1 !important;
}

/* .right-side.right-side-open {
  padding-left: 18rem;
} */

.sidenav {
  transition: all 0.5s;
}

.hamburger {
  cursor: pointer;
  padding: 10px;
  font-size: 40px;
  margin-right: 4rem;
  display: none;
}

.hamburger span {
  display: block;
  width: 20px;
  height: 3px;
  background-color: #333;
  margin: 2px 0;
  transition: 0.4s;
}

.adtAS {
  color: #219653;
}
.adtCS {
  color: #bdbdbd;
}

.auditFormModal .ant-modal-footer button,
.escalationModal .ant-modal-footer button,
.audtModal .ant-modal-footer button,
.duplicationTransactionReasonModal .ant-modal-footer button {
  color: #ffffff !important;
  background: #5e6278 !important;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 30px;
  line-height: normal;
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 0.25rem !important;
  border: 0 !important;
}

div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
div.ant-tabs-tab:hover {
  color: #b22d2b;
}
div.ant-tabs-ink-bar {
  background: #b22d2b;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-picker-focused,
input.ant-input:focus,
input.ant-input-focused,
input.ant-input:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.auditDT input.form-control:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #b22d2b !important;
}

.auditDT input.form-control,
.ant-picker,
.ant-picker-focused {
  padding: 0.5rem !important;
  border: 1px solid #d9d9d9;
}
.auditDT input.form-control::placeholder {
  font-size: 14px;
  color: #cccccc;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  color: #b22d2b;
  line-height: 1;
  content: " *";
}

.menuActive span.settings {
  color: #ffff;
}

.auditBackDateReason-label,
.auditBackDateReason-label {
  white-space: "normal";
  font-weight: "600";
  padding: 0;
}
.ant-modal.reasonModal .ant-modal-footer button {
  color: #ffffff !important;
  background: #5e6278 !important;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 30px;
  line-height: normal;
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border: 0;
  border-radius: 0.25rem !important;
}

/* .auditBackDateReason-label-asterisk{color: "red";  margin-right: "10px";   color: "#ff4d4f";    font-size: "14px";   font-weight: "600";    line-height: 1;  display: "inline-block";} */
.upload-list-block {
  display: block;
  box-shadow: 1px 1px 5px 1px;
  padding: 15px;
}
.upload-list-block
  .ant-upload.ant-upload-select.ant-upload-select-picture
  button:hover {
  color: #b22d2b;
  border-color: #b22d2b;
}

/*Responsive Css Styling*/
@media (max-width: 1900px) {
  div .main-sign-out {
    padding: 5px 12px;
  }
  .sidenav.open a.links {
    padding: 15px 0px 15px 30px;
  }
  div .top-header {
    padding: 0px 20px;
  }
  .right-side {
    width: calc(100% - 0px);
  }

  .right-side.right-side-open {
    width: calc(100% - 280px);
  }

  .sidenav {
    width: 0 !important;
    opacity: 0;
    padding: 0 !important;
  }
  .sidenav.open {
    width: 280px !important;
    opacity: 1;
    /* position: fixed; */
    z-index: 99;
  }
  .hamburger {
    display: block;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }
  .logo-img {
    width: 100px !important;
  }
  .top-header .qcc_logo {
    font-size: 20px;
    line-height: 58px;
  }
}
