.escalationViewC .text11{font-size:16px;}
.escalationViewC .avatar{ background-color: '#1074BC';color: 'white';}
.escalationViewC .scrollbar .text11:nth-child(1) {    padding-left: 20px;}
.escalation-details-left {    font-size: 14px;    margin-left: 5px;    margin-top: 5px;}

.popup-div{
    position:absolute;
    z-index:10;
    border: 2px solid black;
    bottom:40px;
    left:70vh;
    width:720px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    padding:10px;
       
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
background: #FFFFFF;
/* identical to box height */


color: #000000;

 }

 .text__area{
    border: 1px solid #F0F0F0;
border-radius: 5px;
 }

.lineVertical{

width: 0px;
height: 36px;
margin-left: 53px;
margin-top:-10px;


/* color-border-light */

border: 2px solid #F0F0F0;
}

.box_tag{
    
    background: rgba(22, 140, 226, 0.13);
    border-radius: 15px;
    padding :5px;
    font-size: 12px;
    color: #1875CB;
}
.container1.escalationViewC .scrollbar .text11{margin-bottom:24px;}