.count-inner {
  display: flex;
  align-items: center;
  width: 50%;
  float: left;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.tableTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: #152327;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.btn-wraps {
  display: flex;
}
.btn-wraps .right-buttons {
  justify-content: end;
}
.feedbackInfoContainer span:first-child {
  margin-left: 15px;
}
.feedbackInfoContainer span:not(.feedbackInfoContainer span:first-child) {
  margin-left: 5px;
}
.feedbackInfoContainer span {
  padding: 6px 16px;
  border-radius: 20px;
  font-weight: 900;
}
.frSent {
  background-color: #e1bee7;
}
.frReverted {
  background-color: #dcedc8;
}
.frNotReverted {
  background-color: #ef9a9a;
}
.previewBy span,
.previewBy span:hover {
  padding: 3px 16px;
  border-radius: 20px;
  font-weight: 900;
  background-color: red;
  color: #fff;
  cursor: pointer;
}
