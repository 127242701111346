/* survey.css */
body {
  background: #f4f4f4 !important;
}
.survey-main-root {
  font-family: Arial, sans-serif;
}
  
.survey-page-root {
  max-width: 600px;
  margin: 0 auto;
}
  
.survey-element {
  margin-bottom: 20px;
}
  
.survey-question {
  font-size: 16px;
  margin-bottom: 10px;
}
  
.survey-answer {
  display: flex;
  align-items: center;
}
  
.survey-question-title {
  font-weight: bold;
}
  
.survey-comment {
  margin-top: 10px;
  font-size: 14px;
}

/*begin::Custom Css added*/
.surveyCom-container .sv_q.sv_qstn {
  text-align: left;
}
.surveyCom-container fieldset.sv_qcbc {
  display: flex;
}
.surveyCom-container span.sv_q_required_text {
  color: red;
  font-size: 16px;
}
.surveyCom-container .sv_q.sv_qstn {
   text-align: left;
   padding-top: 20px;
   padding-bottom: 20px;
 }

.surveyCom-container .sv_body {
  max-width: 97.5%;
  width: 100%;
  margin: 0 auto;
}

 .surveyCom-container .sv_q.sv_qstn h5 {
   margin-bottom: 10px;
   margin-top: 0;
 }


@media screen and (max-width: 1024px) {
  .surveyCom-container fieldset.sv_qcbc {
    flex-wrap: wrap;
  }
}
/*end::Custom Css added*/