.ml-2 {
  margin-left: 2rem;
}

.pr-1 {
  padding-right: 1rem;
}
.all-inputs .ant-picker {
  width: 100%;
}

.qmslogo {
  width: 79px;
  height: 50px;
  margin-top: 3px;
  position: absolute;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  /* color-font-light */
  color: #9c9fa0;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.boxh {
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
  width: 32px;
  height: 32px;
  float: right;
  margin-right: 15px;
  top: 5px;
  /* color-secondary */
  background: #ffffff;
  /* color-border */
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.container1 {
  display: flex;
}

.fixedView {
  height: 70px;
  position: fixed;
  bottom: 0%;
  width: 75vw;
  opacity: 1;
  background: #ffffff;
  /* color-border */
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
.auditFormEditIcon {
  color: #1890ff;
}
.drgdrp-outerContainer {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
  margin: 10px;
}

.drgdrp-form-outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drgdrp-form-outerContainer button.submit-btn {
  margin: 10px auto;
}
.drgdrp-form-outerContainer .status {
  border-radius: 5px;
  padding: 10px;
}

.drgdrp-form-innerContainer {
  display: flex;
  gap: 50px;
  margin: 10px;
}
.drgdrp-form-innerContainer {
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
.drgdrp-form-innerContainer .ant-form-item {
  margin-bottom: 0;
  width: 30%;
}

div.drgdrp-form-innerContainer .ant-input-number {
  width: 100%;
}
div.drgdrp-form-innerContainer .text__area {
  width: 100%;
  background-color: #f0f0f0;
}

.other-fields {
  display: flex;
  align-items: center;
  width: 70%;
  gap: 10%;
}
