
.qmslogo{width: 79px;
    height: 50px;
    margin-top: 3px;
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    
    /* color-font-light */
    
    color: #9C9FA0;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    flex-grow: 0;}

    
    
    
    .boxh{
        
    box-sizing: border-box;
    
    text-align: center;
    padding: 5px;
    width: 32px;
    height: 32px;
    float: right;
    margin-right: 15px;
    top: 5px;
    
    /* color-secondary */
    
    background: #FFFFFF;
    /* color-border */
    
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    }
    
    .container1{
        display:flex;
        
    
    }
    
    .fixedView
        { 
            height: 70px; 
            position: fixed; 
            bottom:0%;
            width:75vw; 
      
            opacity: 1;
            background: #FFFFFF;
    /* color-border */
    
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
        }
    
    
    
        
    #style-4::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }
    
    #style-4::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-4::-webkit-scrollbar-thumb
    {
        background-color: #000000;
        border: 2px solid #555555;
    }
.performEscalation a,
.performEscalation a:hover{
    color: black;
}

.performEscalation {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 10px;
  color: #152327;
  cursor: pointer; 
}

.performEscalation a,
.performEscalation a:hover {
  color: black;
}

.performEscalation a,
.performEscalation a:hover {
  color: black;
}