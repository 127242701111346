.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.tableTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #152327;
  white-space: nowrap;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.rowstyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}
.qualityParameters{
  font-weight: 600;
  font-size: 16px;
  padding-left:3px;
}