.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

div .table-buttons {
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  margin: 0 10px 0 0;
  cursor: pointer;
}
div .auditDelete {
  background: red;
}
div .auditComplete {
  background: #4caf50;
}
.right-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  justify-content: flex-end;
  gap: 10px;
}
.right-buttons .downloadAllBtn {
  display: none;
}
.right-buttons .cusdownloadAllBtn {
  background: rgb(76, 175, 80);
}

.performAudit,
.downloadAllLoader-container span {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 10px;
  color: #152327;
  cursor: pointer;
  /* margin-left: auto; */
}

.tableTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #152327;
  white-space: nowrap;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.rowstyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.headermodal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.transactionDuplicateReason,
.backDateAuditReason {
  padding: 0 !important;
}
.transactionDuplicateReason label,
.backDateAuditReason label {
  height: 42px !important;
}
.transactionDuplicateReason label::before,
.backDateAuditReason label::before {
  content: "" !important;
}
.performAudit a,
.performAudit a:hover {
  color: black;
}
.auditBackDateReasonRow,
.auditBackDateReasonRow a,
.auditBackDateReasonRow a span {
  background-color: #ca5050;
  color: #fff !important;
}

/*Download All on Audits*/
.downloadMdl .ant-modal-footer {
  display: flex;
  gap: 10px;
  justify-content: end;
}
div.downloadMdl .ant-modal-footer div:first-child {
  position: relative !important;
}

div.downloadMdl .ant-modal-footer div .loader {
  display: none;
  position: absolute;
  background: #f1f1f180;
  top: 0;
  width: 114px;
  height: 38px;
  border-radius: 3px;
}
.downloadAllLoader-container {
  position: relative;
}
.downloadAllLoader {
  width: 100%;
  text-align: center;
}
.activeLoad {
  display: flex !important;
}
.downloadAllLoader div {
  font-size: 1rem;
  font-weight: bold;
}
/* .loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #000000;
  border-radius: 50%;
  width: 28px;
  height: 29px;
  animation: spin 2s linear infinite;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
