.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.tableTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: #152327;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
