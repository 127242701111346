.text123,.dateText123{
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}


.ant-radio-checked .ant-radio-inner{
    border-color: #152327 !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #152327;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #152327 ;
  }