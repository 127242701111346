.ant-form label.weightTage {
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}

#antdformstyle .ant-input-number {
  width: 100%;
}
#antdformstyle .ant-input,
.ant-picker,
.ant-picker-focused {
  padding: 4px 11px !important;
}
#antdformstyle
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px !important;
}
