.vformsTab{padding:0 0 15px 0;}

.forms-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.forms-buttons .right-buttons {
    width: auto;
    padding: 0;
    justify-content: end;
}
.forms-buttons .right-buttons .auditDelete {
    margin: 0;
}