

.auditViewC .text11{font-size:16px;}
.auditViewC .scrollbar .text11:nth-child(1) {    padding-left: 20px;}
.feedback-content {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    color: #152327;
    letter-spacing: 0.3px;
}
.audit-detailscontainer {
    border-width: 1px 1px 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: initial;
    border-left-style: solid;
    border-top-color: rgb(224, 224, 224);
    border-right-color: rgb(224, 224, 224);
    border-bottom-color: initial;
    border-left-color: rgb(224, 224, 224);
    border-image: initial;
    width: 30vw;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: -1px;
}

.audit-details-left {
    font-size: 14px;
    margin-left: 5px;
    margin-top: 5px;
}

.audit-id {
    font-size: 23px;
    color: #152327;
    font-weight: 600;
}
.qms {
    font-weight: 600;
    font-size: 29px;
    line-height: normal;
    color: gray;
    margin: 0px 0 0 0;
}
.inner-header {
    padding: 8.2px 20px;
}

.submitAudit{
    float: 'right';    margin: '15px'; color: '#FFFFFF'; font-size: '12px'; background: '#16AD52'; border-radius: '5px'; height: '40px'; font-weight: '400'; margin-right: "40px";
}

.qmslogo{width: 79px;
height: 50px;
margin-top: 3px;
position: absolute;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;

/* color-font-light */

color: #9C9FA0;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;}


.boxh{
    
box-sizing: border-box;

text-align: center;
padding: 5px;
width: 32px;
height: 32px;
float: right;
margin-right: 15px;
top: 5px;

/* color-secondary */

background: #FFFFFF;
/* color-border */

border: 1px solid #E0E0E0;
border-radius: 5px;
}

.container1{
    display:flex;
    

}

.auditedVInfo{ 
    height: 70px;
    position: fixed;
    bottom:0%;
    width:75.6vw;
    opacity: 1;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
}
.auditProfileImage{width: 25px;border-radius: 50%;}
.auditedVInfo .scoreInfo.bottom-users img{width:41px;height:41px;border-radius: 50%;}

#style-4::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);	background-color: #F5F5F5;}
#style-4::-webkit-scrollbar{	width: 10px;	background-color: #F5F5F5;}
#style-4::-webkit-scrollbar-thumb{	background-color: #000000;	border: 2px solid #555555;}
    
.details-container{
        margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
}
.details-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;margin-bottom: 30px;
}
.details-inner {
    width: 49%;
}

.details-inner input,.details-inner select{width:100%!important;}
.details-inner .headermodal {
    margin-top: 0px;
        margin-bottom: 10px;
        font-size: 14px;
}
.details-inner .ant-select,.details-inner .ant-picker{
    width: 100%;
}

.avpageTop-container{display: flex;    justify-content: space-between;margin-right: 10px;    font-weight: 600;}