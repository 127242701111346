.container-fluid{
  margin:40px 0;
}
.filters-container,.card-container,.chart-container{
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  width: 100%;
  margin:24px 0;
}
.card-container .card{
  background-color:#ffffff;
  width:15%;
  border-radius: 5px;
  padding: 25px 10px;
}
.card-container .card p,.chart-container.first-chart .chart-inner p,.chart-container.second-chart .chart-inner p{
  font-size: 1rem;
  font-weight: 600;
  border-bottom:1px solid #000;
}
.card-container .card span{
  font-size: 0.85rem;
}

.collapse-filters{
  width: 100%;
}

.chart-container.second-chart .chart-inner,
.chart-container.first-chart .chart-inner{
  background-color: #fff;
  padding: 20px;
}

.chart-container.first-chart .chart-inner{
  width:100%; 
}

.chart-container.second-chart{
  gap:20px;display: flex;
  padding:10px;
}
.chart-container.second-chart .chart-inner{
  width:50%;
}
.mt-20{
  margin-top: 20px;
}

.dsh-download-pdf{    
  position: absolute;
  top: -36px;
  right: 0;
  margin: 0;
}

.auditCountBarChart {right:123px;}

.auditCountTable-outer-container{    
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}
.cwac{
  padding-bottom:20px;
  margin-bottom:20px;
  border-bottom:1px solid #ccc;
}
.gp-10{
  gap:10px
}
.auditCountTable-outer-container .auditCountBarChart-dsh-download-excel{
  /* margin-bottom: 16px;
  position: absolute;
  right: 0; */
  width: 20%;
}
