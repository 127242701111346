:root {
  --buttonColorRed: #b12a26;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

section .dHeader {
  color: #b22d2b;
  background-color: #fff;
  text-align: center;
  font-size: 24px;
}

.dContent {
  padding: 20px;
}

div.subMnuChildActive a.links {
  padding: 15px 0px 15px 60px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  height: 100vh;
}

.auditViewC .auditFormField {
  padding-left: 20px;
}
.ant-form-item.auditFormField div div label.ant-form-item-required::before {
  display: none !important;
}
.success {
  background: #16ad52;
}

.customLoader1 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b1b1b1;
  min-height: 100vh;
  color: #000;
  font-size: 20px;
  left: 0;
  top: 0;
}

.customLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4f4f4f7d;
  min-height: 100vh;
  left: 0;
  top: 0;
}

.cust-loader1 {
  border: 0.2em solid currentcolor;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: 1s loader linear infinite;
  animation: 1s loader linear infinite;
  position: relative;
  width: 1em;
  height: 1em;
  color: var(--buttonColorRed);
  vertical-align: middle;
  pointer-events: none;
  font-size: 40px;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.cust-loader {
  width: 2em;
  height: 2em;
  border: 0.3em solid rgba(0, 0, 0, 0.2);
  border-top-color: var(--buttonColorRed);
  border-radius: 50%;
  animation: spin 0.8s ease infinite;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error {
  color: red !important;
  font-size: 11px;
}

.reviewAuditPopup textarea.error,
.reviewAuditPopup textarea.error:focus-visible {
  margin-bottom: 0 !important;
  border-color: red !important;
}
.reviewAuditPopup .error {
  margin-bottom: 10px;
  font-weight: normal !important;
}

.countAuditForms {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* color-font-light */

  color: #9c9fa0;
}
.filterbox {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 14px;
  gap: 10px;

  position: absolute;
  width: 159px;
  height: 38px;
  left: 158px;
  top: -4px;

  /* color-secondary */

  background: #ffffff;
  /* color-border */

  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.filterText {
  width: 101px;
  height: 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* color-font */

  color: #152327;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.tableAuditForms {
  position: absolute;
  left: 283px;
  top: 120px;
  width: 83%;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.tableTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: #152327;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.rowstyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

/* Center the container both horizontally and vertically */

div.underMaintainance-container,
.center-container {
  width: 100%;
  height: 100vh;
  /* Set height to 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.underMaintainance-container .text1,
.centered-text {
  color: #bb2e2c;
  text-shadow: -1px 1px 5px #ffe7b6;
}

.underMaintainance-container .uc {
}

.survey.feedback {
  background-color: rgb(255 252 233);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey .feedbtitle h3 {
  font-size: 8rem;
  text-transform: uppercase;
  color: #febe31;
  letter-spacing: 13px;
  text-align: center;
  line-height: normal;
  font-family: "Bebas Neue", sans-serif;
  margin: 0;
}

.survey .content-holder h4 {
  font-size: 2rem;
  margin: 0 0 22px 0;
}

.survey .content-holder p {
  font-size: 20px;
}

.survey .content-holder {
  border: 1px solid #000000;
  border-radius: 2rem;
  padding: 3rem;
  text-align: center;
  letter-spacing: 0.5px;
  max-width: 550px;
}

.survey button.feedbackbutton {
  color: #fff;
  padding: 10px 20px;
  border: none;
  margin: 20px 0 0 0;
  font-size: 18px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  background-color: #000;
  cursor: pointer;
}

.surveyCom-outer-container {
  background: #1ab7fa;
  margin-top: 20px;
}

.surveyCom-inner-container div:first-child {
  text-align: center;
  color: white;
  font-size: 38;
}

.feedBackPreview .backContainer,
.auditViewPreview .backContainer {
  border-bottom: 0.5px solid #e0e0e0;
  padding: 17px 18px;
  background-color: #ffffff;
}

.feedBackPreview .backContainer .text11,
.auditViewPreview .backContainer .text11 {
  line-height: normal;
  color: #ffffff;
  background: #b22d2b;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5.5px;
  padding: 4px 15px;
}
.underMaintainance-container {
  background-color: #f1f3f400;
}

.underMaintainance-container svg {
  margin-bottom: 30px;
  max-width: 150px;
  fill: #87d185;
}
.under-inner h2.text1 {
  font-size: 18px;
  color: #2a2727;
  text-shadow: none;
  font-weight: 500;
  letter-spacing: 0.85px;
  line-height: 30px;
}
.under-inner .text1 span {
  font-weight: 600;
}

.dsh-filter-style .ant-select-selector {
  align-items: center;
  border-radius: 4px !important;
  height: auto !important;
}

.dsh-submit-btn.main-sign-out {
  color: #ffffff;
  background: #b22d2b !important;
  border: none;
  font-size: 14px;
  border-radius: 5.5px;
  padding: 7px 20px;
  line-height: normal;
  height: auto;
  font-weight: 600;
}
.panel-title .ant-collapse-header-text {
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.9px;
}
.dsh-chart-p {
  font-weight: 600;
  font-size: 17px;
}
.dsh-cards p {
  font-size: 15px;
  letter-spacing: 0.3px;
}
.dsh-cards span {
  font-weight: 600;
  font-size: 17px;
}
.dContent .dsh-cards {
  border: 1px solid #d9d9d9;
  background-position: right top;
  background-size: 30% auto;
  background-repeat: no-repeat;
  background-color: transparent;
}

.dContent div.dsh-cards.overallauditscore {
  background-image: url(../src/assets/abstract-1.svg);
}
.dContent div.dsh-cards.auditmonth {
  background-image: url(../src/assets/abstract-2.svg);
}
.dContent div.dsh-cards.auditcount {
  background-image: url(../src/assets/abstract-4.svg);
}
.dContent div.dsh-cards.invoicecount {
  background-image: url(../src/assets/abstract-5.svg);
}
.dContent div.dsh-cards.transactionamount {
  background-image: url(../src/assets/abstract-1.svg);
}
.dContent div.dsh-cards.fatalcount {
  background-image: url(../src/assets/abstract-2.svg);
}
.dContent div.dsh-cards.not-fatalcount {
  background-image: url(../src/assets/abstract-4.svg);
}

.dsh-table th.ant-table-cell {
  font-weight: 600;
}

.recharts-responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 400px !important;
}
.auditCount-container-header {
  display: flex;
  justify-content: space-between;
}
.auditCount-container-header .dsh-chart-p.vDetails {
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center; /* Aligns triangle and text vertically */
  cursor: pointer;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* Adjust width */
  border-right: 5px solid transparent; /* Adjust width */
  border-top: 10px solid black; /* Adjust color */
  margin-right: 5px; /* Space between triangle and text */
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* Adjust width */
  border-right: 5px solid transparent; /* Adjust width */
  border-bottom: 10px solid black; /* Adjust color */
  margin-right: 5px; /* Space between triangle and text */
}

.searchCon {
  display: flex;
  justify-content: end;
  padding: 0px 0px 10px 0px;
}
.searchCon .table-search {
  width: 28%;
}

.filters-container .panel-title .ant-collapse-header-text {
  background-color: unset;
}

@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
